import {Link} from "react-router-dom";

export default function VideoDetailsLink({name, linkPath}) {
    return (
        <div className="video-details py-3">
            <h3 className="fs-1 lh-1 m-0 fw-normal">{name}</h3>
            <div className="fs-5">
                <Link to={linkPath}
                      className="text-decoration-none text-accent fw-light"
                >
                    <span className="me-3">
                        VIEW DETAILS
                    </span>

                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="15.591"
                         height="13.572"
                         viewBox="0 0 15.591 13.572">
                        <path
                            d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                            transform="translate(0 0)"
                        />
                    </svg>
                </Link>
            </div>
        </div>
    );
}